<template>  
    <v-container>  
      <v-switch v-model="showPassage" label="Show Passage"></v-switch>  
      <div v-show="showPassage" ref="passage">  
        <p>  
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris lobortis  
          dolor ut nunc commodo ultrices. Nam eget leo vel massa venenatis  
          malesuada. Quisque pharetra urna sit amet nisi mollis, ac posuere diam  
          sagittis. Nullam ut enim in risus pellentesque efficitur eget ut  
          mauris. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices  
          posuere cubilia curae; Vestibulum ante ipsum primis in faucibus orci  
          luctus et ultrices posuere cubilia curae; Pellentesque id nisi vitae  
          nibh eleifend feugiat. Nulla facilisi. Vestibulum ante ipsum primis in  
          faucibus orci luctus et ultrices posuere cubilia curae; Sed in mauris  
          auctor, bibendum justo non, malesuada felis. Donec imperdiet lectus  
          vitae nisi blandit, vel ullamcorper ipsum tristique. Sed id tristique  
          dolor. Etiam nec commodo eros. Donec elementum quam vel arcu  
          ullamcorper, vitae suscipit nisl ultrices.  
        </p>  
      </div>  
      <div v-show="!showPassage" ref="spacer">
        <v-container class="fill-height d-flex align-center justify-center">  
            <v-layout align-center justify-center>  
                <v-flex>  
                    <h5 class="text-center">This statement is centered.</h5>  
                </v-flex>  
            </v-layout>  
        </v-container>  
    </div>  
    <h3>Line Test</h3>
    </v-container>  
  </template>  
    
  <script>  
  export default {  
    data() {  
      return {  
        showPassage: true, 
        passageOffsetHeight: 0,
      };  
    },  
    watch: {  
      showPassage() {  
        // Update spacer height when passage visibility changes  
        this.$nextTick(() => {  
          this.setSpacerHeight();  
        });  
      },  
    },  
    methods: {  
      setSpacerHeight() {  
        
        // const passage = this.$refs.passage;  

        // console.log(passage.offsetHeight)
        const spacer = this.$refs.spacer;  

        // spacer.style.height = "232px";  // 216
        spacer.style.height = this.passageOffsetHeight;  // 216

        // const passage = this.$refs.passage;  
        // console.log(passage.offsetHeight)
        // const spacer = this.$refs.spacer;  
        // if (passage && spacer) {  
        //     spacer.style.height = passage.offsetHeight + "px";  
        //     console.log(passage.offsetHeight)

        //     // spacer.style.height = `${passage.offsetHeight}px`;  
        // }  
      },  
    },  
    mounted() {  
      // Set initial spacer height to match passage height  
    //   this.setSpacerHeight();  
    const passage = this.$refs.passage;  
    this.passageOffsetHeight = (passage.offsetHeight + 16) + "px"
    console.log(this.passageOffsetHeight)
    },  
  };  
  </script>  
    
  <style>  
    .hidden {  
      visibility: hidden;  
      height: 0;  
      margin: 0;  
      padding: 0;  
    }  
  </style>  
  