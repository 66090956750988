<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
    <v-toolbar-title @click="$router.push('/')">
      <span class="font-weight-light white--text" style="font-size: 25px">tutored br</span>
      <span class="bold white--text" style="font-size: 30px">AI</span>
      <span class="font-weight-light white--text" style="font-size: 25px">n</span>
    </v-toolbar-title>
    <!-- <v-spacer></v-spacer>
      <span class="font-weight-light white--text" style="font-size: 11px">READING / WRITING</span> -->
    <v-spacer></v-spacer>
      <v-menu
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="yellow"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item link to="contactus">
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
