<template>
    <div>
      <v-row justify="space-around">
        <v-col cols="auto">
          <v-dialog v-model="dialogConfirmation" persistent max-width="600">
            <v-card>
              <v-toolbar color="primary" dark>Confirm</v-toolbar>
              <v-card-text>
                <div class="text-h7 mx-12 my-6">
                  {{ msg }}
                </div>
              </v-card-text>

              <v-textarea
                v-if="showDescription"
                class="mx-12"
                outlined
                label="Anything to add to this report?"
                v-model="description"
              ></v-textarea>

              <v-card-actions class="justify-center">
                <v-btn class="mb-5 mr-6" color="primary" @click="closeCancel"
                  >Cancel</v-btn
                >
                <v-btn class="mb-5 mr-6" color="primary" @click="takeAction"
                  >{{ btnLabel }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-dialog v-model="showProgress">
        <div class="text-center" justify="center" align="center">
          <v-progress-circular
            :size="70"
            :width="7"
            color="blue"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-dialog>
    </div>
  </template>
  
  <script>
  export default {
    props: ["confirmItem","confirmMsg"],
    data() {
      return {
        dialogConfirmation: true,
        showProgress: false,
        showCancel: true,
        description: "",
        // confirmItem: [{id: "1"}],
        // confirmMsg: "report",
      };
    },
    computed: {
      msg: {
        get() {
          if (this.confirmMsg === "report") {
            return (this.msg =
              "Your current chat with AI will be reported to the Development Team. Is there anything else you would like to add to this report?");
          } 
        },
        set() {},
      },
      btnLabel(){
        if (this.confirmMsg === "report") {
          return "Send"
        }
      },      
      showDescription(){
        if (this.confirmMsg === "report") {
          return true
        }
      },
  },
  methods: {
      closeCancel() {
        this.$emit("cancel");
      },
      takeAction(){
        if (this.confirmMsg === "report"){
          this.sendReport()
        }
      },
      async sendReport(){
        const uniqueName = `${Date.now()}`
        this.showProgress = true
        const data = {
          id: uniqueName,
          level: this.confirmItem[0].level,
          passage: this.confirmItem[0].passage,
          messagesAll: JSON.stringify(this.confirmItem[0].messagesAll),
          reqBodyMessages: JSON.stringify(this.confirmItem[0].reqBodyMessages),
          storageName: this.confirmItem[0].storageName,
          description: this.description
        };
        
        const gql1 = `
        mutation create($item: CreatereportissueInput!) {
          createreportissue(item: $item) {
              id
              level
              passage
              messagesAll
              reqBodyMessages
              storageName
              description
            }
          }`;
        
        const query = {
          query: gql1,
          variables: {
            item: data
          } 
        };
        
        const endpoint = "/data-api/graphql";
        try {
          await fetch(endpoint, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(query)
          });
        } catch (error) {
          console.log(error);
        }
        this.$emit("OK");
        this.showProgress = false
      }
    },
};
  </script>
  