import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VAppBar,{attrs:{"app":"","color":"primary","dark":""}},[_c(VToolbarTitle,{on:{"click":function($event){return _vm.$router.push('/')}}},[_c('span',{staticClass:"font-weight-light white--text",staticStyle:{"font-size":"25px"}},[_vm._v("tutored br")]),_c('span',{staticClass:"bold white--text",staticStyle:{"font-size":"30px"}},[_vm._v("AI")]),_c('span',{staticClass:"font-weight-light white--text",staticStyle:{"font-size":"25px"}},[_vm._v("n")])]),_c(VSpacer),_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"yellow"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,[_c(VListItem,{attrs:{"link":"","to":"contactus"}},[_c(VListItemTitle,[_vm._v("Contact Us")])],1)],1)],1)],1),_c(VMain,[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }