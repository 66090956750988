import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"space-around"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.showContact),callback:function ($$v) {_vm.showContact=$$v},expression:"showContact"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_vm._v("For any suggestions or issues, pls send a text (if need be, a screenshot)")]),_c(VCardText,[_c('div',{staticClass:"text-h6 pa-12"},[_c(VIcon,[_vm._v("mdi-google-maps")]),_c('p',[_vm._v("HQ Location: Allen, TX")]),_c(VIcon,[_vm._v("mdi-phone")]),_c('p',[_vm._v("Text: 469-525-0450")]),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.okContact}},[_vm._v("OK")])],1)],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }